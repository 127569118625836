import {
  HANDLE_BUNDLE_SELECT,
  HANDLE_CATEGORY_SELECT,
  HANDLE_SERVICE_SELECT,
  HANDLE_FORM_CHANGE,
  HANDLE_RESET_SELECTION,
  HANDLE_RESET,
  HANDLE_MEETING_FORM_CHANGE,
  HANDLE_MEETING_FORM_SUBMIT,
} from "./types";

export const handleCategorySelect = (e) => (dispatch) => {
  dispatch({ type: HANDLE_CATEGORY_SELECT, payload: e.target.title });
};
export const handleServiceSelect = (e) => (dispatch) => {
  dispatch({ type: HANDLE_SERVICE_SELECT, payload: e });
};
export const handleBundleSelect = (e) => (dispatch) => {
  dispatch({ type: HANDLE_BUNDLE_SELECT, payload: e });
};
export const handleFormChange = (e) => (dispatch) => {
  dispatch({ type: HANDLE_FORM_CHANGE, payload: e });
};

export const handleResetSelection = () => (dispatch) => {
  dispatch({ type: HANDLE_RESET_SELECTION });
};
export const handleReset = () => (dispatch) => {
  dispatch({ type: HANDLE_RESET });
};

export const handleMeetingFormChange = (e) => (dispatch) => {
  dispatch({ type: HANDLE_MEETING_FORM_CHANGE, payload: e });
};
export const handleMeetingFormSubmit = () => (dispatch) => {
  dispatch({ type: HANDLE_MEETING_FORM_SUBMIT });
};
