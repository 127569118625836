import React, { useEffect } from "react";
import { motion } from "framer-motion";
import useIntersect from "../../util/useIntersect";
const AboutTextSection = ({ title, text, tag }) => {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-150px",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.getElementById(`text-about-${tag}`).classList.add("show-line");
      }
    }
  }, [entry.isIntersecting]);
  return (
    <div className="about-text" ref={ref}>
      <h1
        className={`section-title left-end ${tag > 0 && "red"}`}
        id={`text-about-${tag}`}
      >
        {title}
      </h1>
      <p className="about-p1">{text}</p>
    </div>
  );
};

export default AboutTextSection;
