import React, { useEffect, useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Slant as Hamburger } from "hamburger-react";
import { Link } from "react-scroll";
import { connect } from "react-redux";
import { motion } from "framer-motion";
const MobileNav = ({ main }) => {
  // const [position, setPosition] = useState(main.position);
  // const [scroll, setScroll] = useState(false);
  const [menuOneIsClicked, setMenuOneIsClicked] = useState(false);
  const [menuOneOut, setMenuOneOut] = useState(true);
  const [menuTwoOut, setMenuTwoOut] = useState(true);
  const menuOneClick = () => {
    setMenuTwoOut(menuOneIsClicked);
    setMenuOneIsClicked(!menuOneIsClicked);
    setMenuIsClicked(false);
  };
  const [menuIsClicked, setMenuIsClicked] = useState(false);
  const menuClick = () => {
    setMenuOneOut(menuIsClicked);
    setMenuOneIsClicked(false);
    setMenuIsClicked(!menuIsClicked);
  };
  const animations = {
    initial: { y: 100 },
    animate: { y: 0 },
    exit: { y: 100 },
  };
  // let isScrolling;
  // const setScrolling = () => {
  //   setScroll(true);
  //   window.clearTimeout(isScrolling);
  //   isScrolling = setTimeout(function () {
  //     if (scroll) {
  //       setScroll(false);
  //     }
  //   }, 50);
  // };

  // useEffect(() => {
  //   function watchScroll() {
  //     window.addEventListener("scroll", setScrolling, false);
  //   }
  //   watchScroll();
  // });

  // useEffect(() => {
  //   if (main && !scroll) {
  //     setPosition(main.position);
  //   }
  // }, [scroll, main]);

  return (
    <div className="mob-nav-container">
      <motion.div
        variants={animations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.4 }}
        // style={{ listStyle: "none" }}
        className={`blue-nav menu-unchanged`}
      >
        <div
          className={`mob-nav-link ${menuOneIsClicked && "show-nav-text"}  ${
            !menuOneOut && "menu-one-out"
          }`}
          id="mob-nav-home"
        >
          <span className="mob-nav-icon" onClick={menuOneClick}>
            <Hamburger
              toggled={menuOneIsClicked}
              size={20}
              distance="sm"
              rounded
            />
          </span>

          <Link
            to="services"
            smooth="easeInQuad"
            offset={0}
            duration={500}
            isDynamic={true}
            className="mob-nav-item-text"
            onClick={menuOneClick}
          >
            Services
          </Link>
          <Link
            to="projects"
            smooth="easeInQuad"
            offset={0}
            duration={500}
            isDynamic={true}
            className="mob-nav-item-text fix-padding-blue"
            onClick={menuOneClick}
          >
            Web{"\u00A0"}Soultions
          </Link>
        </div>
      </motion.div>
      <motion.div
        variants={animations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.4 }}
        className={`red-nav menu-unchanged`}
      >
        <div
          className={`mob-nav-link-two ${
            menuIsClicked && "show-nav-text-two"
          }  ${!menuTwoOut && "menu-two-out"}`}
          id="mob-nav-home"
        >
          <span className="mob-nav-icon-two" onClick={menuClick}>
            <Hamburger
              toggled={menuIsClicked}
              size={20}
              distance="sm"
              direction="left"
              rounded
            />
          </span>

          <Link
            to="contact"
            smooth="easeInQuad"
            offset={0}
            duration={500}
            isDynamic={true}
            className="mob-nav-item-text-two"
            onClick={menuClick}
          >
            Contact
          </Link>
          <Link
            to="about"
            smooth="easeInQuad"
            offset={0}
            duration={500}
            isDynamic={true}
            className="mob-nav-item-text-two fix-padding-red"
            onClick={menuClick}
          >
            Why{"\u00A0"}iano
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  main: state.rootReducer.main,
});

export default connect(mapStateToProps, {})(MobileNav);
