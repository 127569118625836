export const SET_VIDEO_LOADED = "SET_VIDEO_LOADED";
export const SET_VIDEO_NOT_LOADED = "SET_VIDEO_NOT_LOADED";
export const GET_SERVICES = "GET_SERVICES";
export const SET_SERVICE_ANIMATION = "SET_SERVICE_ANIMATION";
export const SET_SERVICE_OPEN = "SET_SERVICE_OPEN";
export const SET_POSITION = "SET_POSITION";
export const SET_MEETING = "SET_MEETING";
export const SET_QUOTE = "SET_QUOTE";

export const HANDLE_CATEGORY_SELECT = "HANDLE_CATEGORY_SELECT";
export const HANDLE_SERVICE_SELECT = "HANDLE_SERVICE_SELECT";
export const HANDLE_BUNDLE_SELECT = "HANDLE_BUNDLE_SELECT";
export const HANDLE_FORM_CHANGE = "HANDLE_FORM_CHANGE";
export const HANDLE_RESET = "HANDLE_RESET";
export const HANDLE_RESET_SELECTION = "HANDLE_RESET_SELECTION";
export const HANDLE_SUBMIT = "HANDLE_SUBMIT";

export const HANDLE_MEETING_FORM_CHANGE = "HANDLE_MEETING_FORM_CHANGE";
export const HANDLE_MEETING_FORM_SUBMIT = "HANDLE_MEETING_FORM_SUBMIT";

export const SET_LITE = "SET_LITE";
export const SET_CANCEL_PRE = "SET_CANCEL_PRE";
export const SET_PLAY_VID = "SET_PLAY_VID";
