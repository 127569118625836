import React, { useEffect, useState } from "react";
import {
  handleServiceSelect,
  handleBundleSelect,
} from "../../../actions/formActions";
import { connect } from "react-redux";

const Page2 = ({ handleServiceSelect, form, handleBundleSelect }) => {
  const [sUnderline, setSUnderline] = useState(false);
  const { services, bundles, selectedCategories } = form.quote;
  const element = document.getElementById("wow");

  // const handleTheClickB = (e) => {
  // console.log(e.target.innerHTML);
  //   setBundles(
  //     bundles.map((c) => {
  //       c.bundles.map((s) => {
  //         if (s.bn === e) {
  //           s.seleceted = !s.seleceted;
  //           console.log(s.seleceted);
  //         }
  //         console.log(s.seleceted);
  //         return s;
  //       });
  //       return c;
  //     })
  //   );
  // };
  return (
    <div id="wow" className="qoute-services-container">
      <div
        className={`shifter ${
          !sUnderline ? "s-underline" : "s-underline-right"
        }`}
      >
        <div className="wrap-it">
          <div onClick={() => setSUnderline(false)} className="fonting">
            Services
          </div>
          <div onClick={() => setSUnderline(true)} className="fonting">
            Bundles
          </div>
        </div>
      </div>
      {!sUnderline && (
        <div className="ssc">
          {services.map(
            (c) =>
              selectedCategories.includes(c.category) && (
                <React.Fragment>
                  <span className="cn-title fonting">{c.category}</span>

                  <div className="sn-quote-container">
                    {c.services.map((s) => (
                      <div
                        className={`sn-quote fonting ${
                          s.seleceted && "border-it"
                        }`}
                        onClick={() => handleServiceSelect(s.sn)}
                      >
                        {s.sn}
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )
          )}
        </div>
      )}
      {sUnderline && (
        <div className="ssc">
          {bundles.map(
            (c) =>
              selectedCategories.includes(c.category) && (
                <React.Fragment>
                  <span className="cn-title fonting ">{c.category}</span>

                  <div className="sn-quote-container">
                    {c.bundles.map((s) => (
                      <div
                        className={`sn-quote1 fonting  ${
                          s.seleceted && "border-it"
                        }`}
                        onClick={() => handleBundleSelect(s.bn)}
                      >
                        <div className="edit-it">{s.bn}</div>
                        <div>
                          <br />
                          <span className="desc-ds-title">{s.desc.ds}</span>
                          <br />
                          <br />
                          <div className="arrange-list">
                            {s.desc.services.map((i) => (
                              <span className="ds-list-item">• {i}</span>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  form: state.rootReducer.form,
});

export default connect(mapStateToProps, {
  handleServiceSelect,
  handleBundleSelect,
})(Page2);
