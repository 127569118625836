import React, { Fragment, useEffect } from "react";
import test from "./Quote_left_font_awesome.svg.png";
import { Avatar } from "@mui/material";
import useIntersect from "../../util/useIntersect";

const Testemonials = () => {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-20% 0% -20% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document
          .getElementById("testemonials-title")
          .classList.add("show-line");
      }
    }
  }, [entry.isIntersecting]);
  return (
    <div id="testemonials" ref={ref}>
      <h1 className="section-title" id="testemonials-title">
        Testemonials
      </h1>
      <br />
      <div className=" testemonials">
        <div className="testemonial">
          <div className="test-info">
            <Avatar
              src={test}
              sx={{
                fontSize: "1rem",
                padding: "0.8rem",
                width: "4rem",
                height: "4rem",
                backgroundColor: "rgb(46, 123, 255)",
              }}
            />
          </div>
          <div className="test-text">
            <div>
              Iano Marketing elevated our brand with outstanding content, from
              engaging videos to eye-catching posters. Their creativity and
              commitment made a real difference for us.
            </div>
            <span>Mr. Khidr Adam</span>
            <span>
              <b>Snack Attack</b>
            </span>
          </div>
        </div>
        <div className="testemonial">
          <div className="test-info">
            <Avatar
              src={test}
              sx={{
                fontSize: "1rem",
                padding: "0.8rem",
                width: "4rem",
                height: "4rem",
                backgroundColor: "rgb(46, 123, 255)",
              }}
            />
          </div>
          <div className="test-text">
            <div>
              Iano Marketing gave Namaa Fruits a fresh, polished look with a
              stunning website and sleek business cards, bringing our brand to
              life!
            </div>
            <span>Mr. Abdelnasser Khallaf</span>
            <span>
              <b>Namaa Fruits</b>
            </span>
          </div>
        </div>
        <div className="testemonial">
          <div className="test-info">
            <Avatar
              src={test}
              sx={{
                fontSize: "1rem",
                padding: "0.8rem",
                width: "4rem",
                height: "4rem",
                backgroundColor: "rgb(46, 123, 255)",
              }}
            />
          </div>
          <div className="test-text">
            <div>
              With a fresh brand identity and standout marketing, Taha Medical
              now has a vibrant presence that sets us apart, thanks to Iano!
            </div>
            <span>Eng. Almodather Taha</span>
            <span>
              <b>Taha Medical</b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testemonials;
