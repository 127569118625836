import React from "react";
import { connect } from "react-redux";
import { handleFormChange } from "../../../actions/formActions";

const Page3 = ({ handleFormChange, form }) => {
  return (
    <div>
      <form
        id="quote-form"
        name="quoteform"
        action="/confirmation"
        method="POST"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="Quotes" />
        <div>
          <label>Your name</label>
          <input
            onChange={handleFormChange}
            name="name"
            type="text"
            value={form.quote.data.name}
          />
        </div>
        <div>
          <label>Company name</label>
          <input
            onChange={handleFormChange}
            name="company"
            type="text"
            value={form.quote.data.company}
          />
        </div>

        <div>
          <label>E-mail</label>
          <input
            onChange={handleFormChange}
            name="email"
            type="email"
            value={form.quote.data.email}
          />
        </div>

        <div>
          <label>Phone Number</label>
          <input
            onChange={handleFormChange}
            name="phone"
            type="tel"
            // min="0"
            value={form.quote.data.phone}
          />
          <span>(+xxx) xxx xxx xxx</span>
        </div>
        <div style={{ width: "100%" }}>
          <label>Estimated Budget</label>
          <input
            onChange={handleFormChange}
            name="budget"
            type="number"
            style={{ width: "100%" }}
            value={form.quote.data.budget}
          />
          <span>$ (xxxx)</span>
        </div>
        <div style={{ display: "none" }}>
          <input type="text" name="services" value={form.quote.data.services} />
          <input type="text" name="bundles" value={form.quote.data.bundles} />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  form: state.rootReducer.form,
});

export default connect(mapStateToProps, {
  handleFormChange,
})(Page3);
