import React, { Fragment, useEffect } from "react";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useIntersect from "../../util/useIntersect";

import {
  faFacebookF,
  faYoutube,
  faInstagram,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
const ContactDetails = () => {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-150px",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document
          .getElementById("contact-details-title")
          .classList.add("show-line");
      }
    }
  }, [entry.isIntersecting]);
  return (
    <div ref={ref} className="d-container">
      <h1 className="section-title left-end" id="contact-details-title">
        Get in touch
      </h1>
      <div className="details-container">
        <div className="contact-details">
          <div className="contact-item">
            <SmartphoneIcon />
            <a href="tel:+254745401564" target="_blank">
              +254 745 401 564
            </a>
          </div>
          <div className="contact-item">
            <WhatsAppIcon />
            <a href="https://wa.me/+254745401564">+254 745 401 564</a>
          </div>
          <div className="contact-item">
            <EmailIcon />{" "}
            <a href="mailto:info@iano.marketing">info@iano.marketing</a>
          </div>
        </div>
        <div className="social">
          <a href="https://www.facebook.com/iano.marketing" target="_blank">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://www.youtube.com/@iano.marketing" target="_blank">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href="https://www.instagram.com/iano.marketing/" target="_blank">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://twitter.com/iano_marketing" target="_blank">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://www.linkedin.com/company/iano" target="_blank">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
