import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setPosition, setMeeting } from "../../../actions/mainActions";
import Meetings from "../../Dialogs/Meetings";
import useIntersect from "../../util/useIntersect";
import Project from "./Project";

const data = [
  {
    title: "E-commerce",
    image: "1",
    shortDesc:
      "Managing your products, services and logistics has never been easier. (Solution for small-medium businesses) ",
    price: "70$/month",
    status: "In progress",
  },
  {
    title: "AR Real Estate",
    image: "2",
    shortDesc: "Visit an environment in 3D, without actually visiting it",
    price: "15$/square meter",
    status: "On hold",
  },
];
const Projects = ({ setPosition, setMeeting }) => {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-active").forEach((i) => {
          i.classList.remove("nav-active");
        });

        document.getElementById("nav-projects").classList.add("nav-active");
        setPosition("projects");

        document.getElementById("projects-title").classList.add("show-line");
      }
    }
  }, [entry.isIntersecting]);
  return (
    <div id="projects" ref={ref}>
      <h1 className="section-title" id="projects-title">
        Our web solutions
      </h1>
      <div className="projects-container">
        {data.map((i, j) => {
          return <Project data={i} key={j} />;
        })}
      </div>
      <div className="prj-qn-div">
        <h1>Are you ready for change?</h1>
        <button onClick={setMeeting}>schedule a meeting</button>
      </div>
      <Meetings />
    </div>
  );
};
export default connect(null, {
  setPosition,
  setMeeting,
})(Projects);
