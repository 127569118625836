import React, { useEffect, useState, useRef, useMemo, memo } from "react";
import hero from "../../../videos/hero.mp4";
import heroimg from "../../../images/sm.png";
import { connect } from "react-redux";

import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";
import { ManOutlined } from "@mui/icons-material";

const Video = ({ f, main }) => {
  // console.log(main);
  useEffect(() => {
    console.log(main.lite);
  }, [main.lite]);
  // const prevProps = useRef();
  // useEffect(() => {
  //   if (prevProps.current && prevProps.current.f === f) {
  //     return;
  //   }
  //   prevProps.current = { f };
  // });
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const togglePlay = () => {
    if (playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPlaying(!playing);
  };
  useEffect(() => {
    if (main.playVid) {
      togglePlay();
    }
  }, [main.playVid]);
  // let location = useLocation();
  // const [hide, setHide] = useState(false);
  // const screenWidth = window.screen.width;
  // const screenHeight = window.screen.height;
  // const screenRatio = screenWidth / screenHeight;
  // useEffect(() => {
  //   // window.document.body.style.overflowY = "scroll";
  //   window.scrollTo(0, 0);
  //   // window.document.body.style.touchAction = "auto";
  //   //eslint-disable-next-line
  // }, []);
  // const vidRef = useRef(null);

  // useEffect(() => {
  //   if (location.pathname !== "/") {
  //     setHide(true);
  //     vidRef.current.pause();
  //   } else {
  //     setHide(false);
  //     vidRef.current.play();
  //   }
  // }, [location]);

  return (
    <section
      className="hero"
      id="home"
      // style={{ opacity: hide ? "0" : "1", transitionDelay: !hide && "0.7s" }}
    >
      <div className="hero-container">
        <div className="video-container">
          <img
            src={heroimg}
            alt="hero"
            className={`heroimg ${!main.lite && "disnone"}`}
          />

          <video
            ref={videoRef}
            // ref={vidRef}
            // autoPlay
            playin
            playsInline
            muted
            id="hero"
            preload={"auto"}
            onCanPlayThrough={() => {
              f(true);
            }}
          >
            <source src={hero} type="video/mp4"></source>
          </video>

          <div className="overlay" />
          <div className="blend" />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  main: state.rootReducer.main,
});

export default connect(mapStateToProps, {})(memo(Video));
