import React from "react";
import RequestQuoteRoundedIcon from "@mui/icons-material/RequestQuoteRounded";
import { setQuote } from "../../actions/mainActions";
import { connect } from "react-redux";
// import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
const ActionButoon = ({ setQuote, s }) => {
  // const handleClick = () => console.log("Dsd");
  return (
    <div>
      <button className="action-quote" onClick={() => setQuote(s)}>
        Request a quote
      </button>
    </div>
  );
};

export default connect(null, {
  setQuote,
})(ActionButoon);
