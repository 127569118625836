import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-scroll";
import logo from "../../images/logo.svg";
import MobileNav from "./MobileNav";
import { motion } from "framer-motion";
const Navbar = () => {
  function logit(e) {
    if (e.target.documentElement.scrollTop > 100) {
      return document.getElementById("nav").classList.add("shrink");
    } else {
      return document.getElementById("nav").classList.remove("shrink");
    }
  }
  const animations = {
    initial: { opacity: 0, x: 10 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 10 },
  };
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  return (
    <Fragment>
      <nav id="nav" className="nav-styles">
        {/* logo */}
        <h2 id="logo">
          <img src={logo} alt="iano" className="logo" />
        </h2>
        {/* navigation */}
        <motion.ul
          variants={animations}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.4, delay: 0.2 }}
          className="rm-list-style nav-list"
        >
          <li className="nav-item">
            <Link
              to="home"
              smooth="easeInQuad"
              offset={0}
              duration={500}
              className="nav-link nav-link-active"
              id="nav-home"
              isDynamic={true}
            >
              <span className="nav-name">Home</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="projects"
              smooth="easeInQuad"
              offset={0}
              duration={500}
              className="nav-link nav-link-active"
              id="nav-projects"
              isDynamic={true}
            >
              <span className="nav-name" id="nav-projects">
                Web Solutions
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="services"
              smooth="easeInQuad"
              offset={0}
              duration={500}
              className="nav-link nav-link-active"
              id="nav-services"
              isDynamic={true}
            >
              <span className="nav-name">Services</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="about"
              smooth="easeInQuad"
              offset={0}
              duration={500}
              className="nav-link nav-link-active"
              id="nav-about"
              isDynamic={true}
            >
              <span className="nav-name">Why iano</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="contact"
              smooth="easeInQuad"
              offset={0}
              duration={500}
              className="nav-link nav-link-active"
              id="nav-contact"
              isDynamic={true}
            >
              <span className="nav-name">Contact</span>
            </Link>
          </li>
        </motion.ul>
      </nav>
      <MobileNav />
    </Fragment>
  );
};

export default Navbar;
