import {
  HANDLE_BUNDLE_SELECT,
  HANDLE_CATEGORY_SELECT,
  HANDLE_FORM_CHANGE,
  HANDLE_MEETING_FORM_CHANGE,
  HANDLE_MEETING_FORM_SUBMIT,
  HANDLE_RESET,
  HANDLE_RESET_SELECTION,
  HANDLE_SERVICE_SELECT,
} from "../actions/types";

const initialState = {
  meeting: {
    name: "",
    company: "",
    email: "",
    phone: "",
    date: "",
    submited: false,
  },
  quote: {
    selectedCategories: [],
    bundles: [
      {
        category: "Digital Branding",
        bundles: [
          {
            bn: "The New Face Bundle",
            seleceted: false,
            desc: {
              ds: "Get the complete brand identity",
              services: [
                "Logo Design",
                "Business Card Design",
                "Brochure Design",
                "Web Designs",
              ],
            },
          },
        ],
      },
      {
        category: "Digital Marketing",
        bundles: [
          {
            bn: "The Million Eyes Bundle",
            seleceted: false,
            desc: {
              ds: "Get the 'all across' web presence",
              services: [
                "PPC (Pay Per Click) Management",
                "Content Creation",
                "Social Media Management",
                "SEO (Search engine optimization)",
                "Email Marketing",
              ],
            },
          },
        ],
      },
      {
        category: "Media Production",
        bundles: [
          {
            bn: "The Booster Bundle",
            seleceted: false,
            desc: {
              ds: "Get sales-converting ads for media platforms",
              services: [
                "Display Ads",
                "Video Ads",
                "Radio Ads",
                "Animation",
                "3D Rendering",
              ],
            },
          },
        ],
      },
    ],
    services: [
      {
        category: "Digital Branding",
        services: [
          { sn: "Logo Design", seleceted: false },
          { sn: "Business Card Design", seleceted: false },
          { sn: "Brochure Design", seleceted: false },
          { sn: "Web Design", seleceted: false },
        ],
      },
      {
        category: "Digital Marketing",
        services: [
          { sn: "PPC (Pay Per Click) Management", seleceted: false },
          { sn: "Content Creation", seleceted: false },
          { sn: "Social Media Management", seleceted: false },
          { sn: "SEO (Search engine optimization)", seleceted: false },
          { sn: "Email Marketing", seleceted: false },
        ],
      },
      {
        category: "Media Production",
        services: [
          { sn: "Display Ads", seleceted: false },
          { sn: "Video Ads", seleceted: false },
          { sn: "Radio Ads", seleceted: false },
          { sn: "Animation", seleceted: false },
          { sn: "3D Rendering", seleceted: false },
        ],
      },
    ],
    data: {
      name: "",
      company: "",
      email: "",
      phone: null,
      services: [],
      bundles: [],
      budget: "",
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_MEETING_FORM_CHANGE:
      return {
        ...state,
        meeting: {
          ...state.meeting,
          [action.payload.target.name]: action.payload.target.value,
        },
      };
    case HANDLE_MEETING_FORM_SUBMIT:
      return {
        ...state,
        meeting: {
          ...state.meeting,
          submited: true,
        },
      };
    case HANDLE_CATEGORY_SELECT:
      if (!state.quote.selectedCategories.includes(action.payload)) {
        return {
          ...state,
          quote: {
            ...state.quote,
            selectedCategories: [
              ...state.quote.selectedCategories,
              action.payload,
            ],
          },
        };
      } else {
        return {
          ...state,
          quote: {
            ...state.quote,
            selectedCategories: state.quote.selectedCategories.filter(
              (i) => i !== action.payload
            ),
          },
        };
      }
    case HANDLE_SERVICE_SELECT:
      if (!state.quote.data.services.includes(action.payload)) {
        return {
          ...state,
          quote: {
            ...state.quote,
            services: state.quote.services.map((c) => {
              c.services.map((s) => {
                if (s.sn === action.payload) {
                  s.seleceted = !s.seleceted;
                  // console.log(s.seleceted);
                }
                return s;
              });
              return c;
            }),
            data: {
              ...state.quote.data,
              services: [...state.quote.data.services, action.payload],
            },
          },
        };
      } else {
        return {
          ...state,
          quote: {
            ...state.quote,
            services: state.quote.services.map((c) => {
              c.services.map((s) => {
                if (s.sn === action.payload) {
                  s.seleceted = !s.seleceted;
                  // console.log(s.seleceted);
                }
                return s;
              });
              return c;
            }),
            data: {
              ...state.quote.data,
              services: state.quote.data.services.filter(
                (i) => i !== action.payload
              ),
            },
          },
        };
      }
    case HANDLE_BUNDLE_SELECT:
      if (!state.quote.data.bundles.includes(action.payload)) {
        return {
          ...state,
          quote: {
            ...state.quote,
            bundles: state.quote.bundles.map((c) => {
              c.bundles.map((s) => {
                if (s.bn === action.payload) {
                  s.seleceted = !s.seleceted;
                  // console.log(s.seleceted);
                }
                // console.log(s.seleceted);
                return s;
              });
              return c;
            }),
            data: {
              ...state.quote.data,
              bundles: [...state.quote.data.bundles, action.payload],
            },
          },
        };
      } else {
        return {
          ...state,
          quote: {
            ...state.quote,
            bundles: state.quote.bundles.map((c) => {
              c.bundles.map((s) => {
                if (s.bn === action.payload) {
                  s.seleceted = !s.seleceted;
                  // console.log(s.seleceted);
                }
                // console.log(s.seleceted);
                return s;
              });
              return c;
            }),
            data: {
              ...state.quote.data,
              bundles: state.quote.data.bundles.filter(
                (i) => i !== action.payload
              ),
            },
          },
        };
      }
    case HANDLE_FORM_CHANGE:
      return {
        ...state,
        quote: {
          ...state.quote,
          data: {
            ...state.quote.data,
            [action.payload.target.name]: action.payload.target.value,
          },
        },
      };
    case HANDLE_RESET_SELECTION:
      return {
        ...state,
        quote: {
          ...state.quote,
          services: [
            {
              category: "Digital Branding",
              services: [
                { sn: "Logo Design", seleceted: false },
                { sn: "Business Card Design", seleceted: false },
                { sn: "Brochure Design", seleceted: false },
                { sn: "Web Design", seleceted: false },
              ],
            },
            {
              category: "Digital Marketing",
              services: [
                { sn: "PPC (Pay Per Click) Management", seleceted: false },
                { sn: "Content Creation", seleceted: false },
                { sn: "Social Media Management", seleceted: false },
                { sn: "SEO (Search engine optimization)", seleceted: false },
                { sn: "Email Marketing", seleceted: false },
              ],
            },
            {
              category: "Media Production",
              services: [
                { sn: "Display Ads", seleceted: false },
                { sn: "Video Ads", seleceted: false },
                { sn: "Radio Ads", seleceted: false },
                { sn: "Animation", seleceted: false },
                { sn: "3D Rendering", seleceted: false },
              ],
            },
          ],
          bundles: [
            {
              category: "Digital Branding",
              bundles: [
                {
                  bn: "The New Face Bundle",
                  seleceted: false,
                  desc: {
                    ds: "Get the complete brand identity",
                    services: [
                      "Logo Design",
                      "Business Card Design",
                      "Brochure Design",
                      "Web Designs",
                    ],
                  },
                },
              ],
            },
            {
              category: "Digital Marketing",
              bundles: [
                {
                  bn: "The Million Eyes Bundle",
                  seleceted: false,
                  desc: {
                    ds: "Get the 'all across' web presence",
                    services: [
                      "PPC (Pay Per Click) Management",
                      "Content Creation",
                      "Social Media Management",
                      "SEO (Search engine optimization)",
                      "Email Marketing",
                    ],
                  },
                },
              ],
            },
            {
              category: "Media Production",
              bundles: [
                {
                  bn: "The Booster Bundle",
                  seleceted: false,
                  desc: {
                    ds: "Get sales-converting ads for media platforms",
                    services: [
                      "Display Ads",
                      "Video Ads",
                      "Radio Ads",
                      "Animation",
                      "3D Rendering",
                    ],
                  },
                },
              ],
            },
          ],
          data: { ...state.quote.data, services: [], bundles: [] },
        },
      };
    case HANDLE_RESET:
      return { ...state, quote: initialState.quote };
    default:
      return state;
  }
};

export default reducer;
