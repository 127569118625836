import React, { Fragment, useEffect } from "react";
// import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { connect } from "react-redux";
import { setMeeting } from "../../actions/mainActions";
import {
  handleMeetingFormChange,
  handleMeetingFormSubmit,
} from "../../actions/formActions";
import { Button } from "@mui/material";

const Meetings = ({
  setMeeting,
  main,
  handleMeetingFormChange,
  form,
  handleMeetingFormSubmit,
}) => {
  useEffect(() => {
    if (main.meetingOpen) {
      window.document.body.style.overflowY = "hidden";
      window.document.body.style.touchAction = "none";
    } else {
      window.document.body.style.overflowY = "scroll";
      window.document.body.style.touchAction = "auto";
    }
  }, [main.meetingOpen]);
  return (
    <Fragment>
      <div
        onClick={setMeeting}
        className={`dialog-background ${
          main.meetingOpen && "meeting-open-background"
        }`}
      ></div>
      <div className={`dialog ${main.meetingOpen && "meeting-open"}`}>
        <span className="sc-close" onClick={setMeeting}>
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </span>

        <h2 className="sc-h1">Schedule a meeting</h2>
        {!form.meeting.submited ? (
          <form
            name="meetingform"
            action="/confirmation"
            method="POST"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="Meetings" />
            <div>
              <label>Name</label>
              <input
                name="name"
                type="text"
                onChange={handleMeetingFormChange}
                value={form.meeting.name}
              />
            </div>
            <div>
              <label>Company</label>
              <input
                name="company"
                type="text"
                onChange={handleMeetingFormChange}
                value={form.meeting.company}
              />
            </div>

            <div>
              <label>E-mail</label>
              <input
                name="email"
                type="email"
                onChange={handleMeetingFormChange}
                value={form.meeting.email}
              />
            </div>

            <div>
              <label>Phone Number</label>
              <input
                name="phone"
                type="tel"
                onChange={handleMeetingFormChange}
                value={form.meeting.phone}
              />
              <span>(+xxx) xxx xxx xxx</span>
            </div>
            <section className="align-center">
              <h2 className="sc-h2">When would you like to meet?</h2>
            </section>
            <section className="radio-sc">
              <section>
                <input
                  type="radio"
                  id="sc1"
                  name="date"
                  value="This week"
                  onChange={handleMeetingFormChange}
                />
                <label htmlFor="sc1">This week</label>
              </section>
              <section>
                <input
                  type="radio"
                  id="sc2"
                  name="date"
                  value="Next week"
                  onChange={handleMeetingFormChange}
                />
                <label htmlFor="sc2">Next week</label>
              </section>
              <section>
                <input
                  type="radio"
                  id="sc3"
                  name="date"
                  value="This month"
                  onChange={handleMeetingFormChange}
                />
                <label htmlFor="sc3">This month</label>
              </section>
              <section>
                <input
                  type="radio"
                  id="sc4"
                  name="date"
                  value="Next month"
                  onChange={handleMeetingFormChange}
                />
                <label htmlFor="sc4">Next month</label>
              </section>
            </section>

            <Button
              style={{ backgroundColor: " rgb(56, 62, 70)" }}
              variant="outlined"
              disabled={
                form.meeting.name === "" ||
                form.meeting.company === "" ||
                form.meeting.email === "" ||
                form.meeting.number === "" ||
                form.meeting.date === ""
              }
              type="submit"
              // onClick={handleMeetingFormSubmit}
            >
              <CalendarMonthIcon />
              <span>Request meeting</span>
            </Button>
          </form>
        ) : (
          <div
            style={{
              paddingTop: "3rem",
              margin: "1rem",
              borderTop: "solid 2px rgb(56, 62, 70)",
            }}
            className="section-desc-head"
          >
            Your request has been sent. We&apos;ll contact you soon.
            <Button onClick={setMeeting}>Close</Button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  main: state.rootReducer.main,
  form: state.rootReducer.form,
});

export default connect(mapStateToProps, {
  setMeeting,
  handleMeetingFormChange,
  handleMeetingFormSubmit,
})(Meetings);
