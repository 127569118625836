import React, { Fragment, useEffect, useState } from "react";
import { ContactError, ContactField } from "react-contacts";

import SmartphoneIcon from "@mui/icons-material/Smartphone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import ProfileAbout from "./ProfileAbout";
import { useParams } from "react-router-dom";
import MM from "../../../images/Team/0.png";
import PageTransition2 from "../../layout/PageTransition2";
// import AnimatedSidePage from "../../layout/AnimatedSidePage";

const Profile = () => {
  const { tag } = useParams();
  const teamDetails = [
    {
      name: "Mohamed Modasser",
      position: "Creative Director | Brand Developer",
      image: MM,
      id: "1",
      email: "modasser@iano.marketing",
      phone: "+254 745 401 564",
      phoneLink: "tel:+254745401564",
      whatsappLink: "https://wa.me/+254745401564",
      emailLink: "mailto:modasser@iano.marketing",
      linkedIn: "https://www.linkedin.com/in/mohamedelmodasser/",
      about: 1,
      portofolio: {
        state: false,
        value: [],
      },
      certificates: {
        state: false,
        value: [],
      },
      tag: "modasser",
    },
  ];
  useEffect(() => {
    // window.document.body.style.overflowY = "scroll";
    window.scrollTo(0, 0);
    // window.document.body.style.touchAction = "auto";
    //eslint-disable-next-line
  }, []);
  // const supported = "contacts" in navigator && "ContactsManager" in window;
  const [results, setResults] = useState("");

  const addContact = (displayName, name, phoneNumber, birthday) => {
    try {
      // Retrieve the contact information from your database or other source
      const contact = navigator.contacts.create({
        displayName: displayName,
        name: name,
        birthday: birthday,
        note: "DeleteMe",
      });

      const phoneNumbers = [1];
      phoneNumbers[0] = new ContactField("work", phoneNumber, true);
      contact.phoneNumbers = phoneNumbers;

      // Save the contact to the user's phone
      contact.save(
        function () {
          setResults((displayName || "Nameless contact") + " saved.");
        },
        function (e) {
          if (e.code === ContactError.NOT_SUPPORTED_ERROR) {
            setResults("Saving contacts not supported.");
          } else {
            setResults("Contact save failed: error " + e.code);
          }
        }
      );
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <div className="profile-container">
      <PageTransition2 />

      {/* <div className="hide-lag" /> */}
      <div className="profile">
        {teamDetails.map((i, s) => {
          if (i.tag === tag) {
            return (
              <Fragment key={s}>
                <div
                  className="proflie-picture"
                  style={{ backgroundImage: `url(${i.image})` }}
                />
                <div className="profile-details">
                  <h1 className="profile-name">{i.name}</h1>
                  <span className="profile-role">{i.position}</span>
                  <div className="profile-contact">
                    <a href={i.emailLink} target="_blank">
                      <EmailIcon />
                      <span>{i.email}</span>
                    </a>
                    <a href={i.phoneLink} target="_blank">
                      <SmartphoneIcon />
                      <span>{i.phone}</span>
                    </a>
                    <a href={i.whatsappLink} target="_blank">
                      <WhatsAppIcon />
                      <span>Whatsapp</span>
                    </a>
                    <a href={i.linkedIn} target="_blank">
                      <LinkedInIcon />
                      <span>LinkedIn</span>
                    </a>
                  </div>
                  <div style={{ width: "100%" }}>
                    <button style={{ width: "100%" }} className="save-contact">
                      {i.id === "1" && (
                        <a
                          className="linkvcard"
                          href="data:text/x-vcard;charset=utf-8,BEGIN%3AVCARD%0AVERSION%3A3.0%0AN%3AModasser%3BMohamed%0AFN%3AMohamed%20Modasser%0ATEL%3BTYPE%3DCELL%3A%2B254745401564%0AEMAIL%3Amodasser%40iano.marketing%0AORG%3Aiano%0AURL%3Ahttps%3A%2F%2Fiano.marketing%0AROLE%3ACreative%20Director%20%7C%20Brand%20Developer%0APHOTO%3Ahttps%3A%2F%2Fibb.co%2FKF136Rt%0AEND%3AVCARD%0A"
                          download="MohamedModasser.vcf"
                        >
                          Save Contact
                        </a>
                      )}
                    </button>
                  </div>
                  <div id="contact_results">{results}</div>
                </div>
              </Fragment>
            );
          }
          return;
        })}
      </div>
      <br />
      <br />
      <br />
      <br />
      {teamDetails.map((i, s) => {
        if (i.tag === tag) {
          return (
            <ProfileAbout
              key={s}
              id={i.id}
              about={i.about}
              portofolio={i.portofolio}
              certificates={i.certificates}
            />
          );
        }
        return;
      })}
      {/* <div className="copyright">
        Copyright © 2020 <b>iano</b>. All rights reserved.
      </div> */}
    </div>
  );
};

export default Profile;
