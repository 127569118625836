import React, { useEffect } from "react";
import FAQ from "./FAQ";

import useIntersect from "../../util/useIntersect";
import ContactForm from "./ContactForm";
import { connect } from "react-redux";
import { setPosition } from "../../../actions/mainActions";
import ContactDetails from "./ContactDetails";

const Contact = ({ setPosition }) => {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-active").forEach((i) => {
          i.classList.remove("nav-active");
        });
        document.getElementById("nav-contact").classList.add("nav-active");
        setPosition("contact");
      }
    }
  }, [entry.isIntersecting]);
  return (
    <div id="contact" ref={ref}>
      <div className="contact">
        {/* <ContactForm /> */}
        <div className="left-contact-wrap">
          <ContactDetails />
        </div>
        <div className="left-contact-wrap">
          <FAQ />
        </div>
      </div>
      <div className="copyright">
        Copyright © 2020 <b>iano</b>. All rights reserved.
      </div>
    </div>
  );
};

export default connect(null, {
  setPosition,
})(Contact);
