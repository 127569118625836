import {
  GET_SERVICES,
  SET_POSITION,
  SET_SERVICE_ANIMATION,
  SET_SERVICE_OPEN,
  SET_VIDEO_LOADED,
  SET_MEETING,
  SET_VIDEO_NOT_LOADED,
  SET_QUOTE,
  SET_LITE,
  SET_CANCEL_PRE,
  SET_PLAY_VID,
} from "./types";

export const setVideoLoaded = () => (dispatch) => {
  dispatch({ type: SET_VIDEO_LOADED });
};
export const setVideoNotLoaded = () => (dispatch) => {
  dispatch({ type: SET_VIDEO_NOT_LOADED });
};
export const getServices = (data) => (dispatch) => {
  dispatch({ type: GET_SERVICES, payload: data });
};
export const setServiceAnimation = (id) => (dispatch) => {
  setTimeout(() => dispatch({ type: SET_SERVICE_ANIMATION, payload: id }), 400);
};
export const setServiceOpen = (id) => (dispatch) => {
  dispatch({ type: SET_SERVICE_OPEN, payload: id });
};
export const setPosition = (position) => (dispatch) => {
  dispatch({ type: SET_POSITION, payload: position });
};
export const setMeeting = () => (dispatch) => {
  dispatch({ type: SET_MEETING });
};
export const setLite = () => (dispatch) => {
  dispatch({ type: SET_LITE });
};
export const setPlayVid = () => (dispatch) => {
  dispatch({ type: SET_PLAY_VID });
};
export const setCancelPre = () => (dispatch) => {
  dispatch({ type: SET_CANCEL_PRE });
};
export const setQuote = (s) => (dispatch) => {
  dispatch({ type: SET_QUOTE, payload: s });
};
