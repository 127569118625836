import React, { useEffect, useState } from "react";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import WebIcon from "@mui/icons-material/Web";
import MovieIcon from "@mui/icons-material/Movie";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { handleCategorySelect } from "../../../actions/formActions";
import { connect } from "react-redux";

const Page1 = ({ handleCategorySelect, form, main }) => {
  const { selectedCategories } = form.quote;

  // const [selectedCategories, setSelectedCategories] = useState([]);
  // const handleSelect = (e) => {
  //   if (selectedCategories.includes(e.target.title)) {
  //     setSelectedCategories(
  //       selectedCategories.filter((i) => i !== e.target.title)
  //     );
  //   } else {
  //     setSelectedCategories([...selectedCategories, e.target.title]);
  //   }

  //   console.log(selectedCategories);
  // };
  return (
    <div className="qoute-services-container">
      <div className="qoute-services-sub-container">
        <div
          className={`quote-service-wrap ${
            selectedCategories.includes("Digital Branding") &&
            "service-selected"
          }`}
          onClick={handleCategorySelect}
          title="Digital Branding"
        >
          {selectedCategories.includes("Digital Branding") && (
            <section className="checked-s" title="Digital Branding">
              <CheckCircleIcon className="disable-click" />
            </section>
          )}
          <h4 title="Digital Branding">
            <span title="Digital Branding" className="fonting">
              Digital Branding
            </span>{" "}
            <ColorLensIcon className="disable-click" />
          </h4>
          <div title="Digital Branding">
            • Identity Design <br />• Product Design <br />• UI/UX Web Design{" "}
            <br />• UX Writing <br />• Content Creation
          </div>
        </div>
        <div
          className={`quote-service-wrap ${
            selectedCategories.includes("Digital Marketing") &&
            "service-selected"
          }`}
          title="Digital Marketing"
          onClick={handleCategorySelect}
        >
          {selectedCategories.includes("Digital Marketing") && (
            <section className="checked-s" title="Digital Marketing">
              <CheckCircleIcon className="disable-click" />
            </section>
          )}
          <h4 title="Digital Marketing">
            <span title="Digital Marketing" className="fonting">
              Digital Marketing
            </span>{" "}
            <WebIcon className="disable-click" />
          </h4>
          <div title="Digital Marketing">
            • Search Engine Optimization (SEO) <br />• Social Media Marketing{" "}
            <br />• Pay Per Click (PPC) Management <br />• Content Writing{" "}
            <br />• Email Marketing
          </div>
        </div>
        <div
          className={`quote-service-wrap ${
            selectedCategories.includes("Media Production") &&
            "service-selected"
          }`}
          onClick={handleCategorySelect}
          title="Media Production"
        >
          {selectedCategories.includes("Media Production") && (
            <section className="checked-s" title="Media Production">
              <CheckCircleIcon className="disable-click" />
            </section>
          )}
          <h4 title="Media Production">
            <span title="Media Production" className="fonting">
              Media Production
            </span>{" "}
            <MovieIcon className="disable-click" />
          </h4>
          <div title="Media Production">
            • Display Ads <br />• Video Ads <br />• Radio Ads <br />• Animation{" "}
            <br />• 3D Rendering
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  main: state.rootReducer.main,
  form: state.rootReducer.form,
});

export default connect(mapStateToProps, {
  handleCategorySelect,
})(Page1);
