import React, { Fragment, useEffect } from "react";
// Import Swiper React components
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import "./styles.css";

// import required modules
// import { EffectCreative } from "swiper";
// Import Swiper styles
import "swiper/css";
// import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import CloseIcon from "@mui/icons-material/Close";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { connect } from "react-redux";
import { setQuote } from "../../actions/mainActions";
import Page1 from "./QuotePages/Page1";
import Page2 from "./QuotePages/Page2";
import Page3 from "./QuotePages/Page3";
import {
  handleResetSelection,
  handleReset,
  handleCategorySelect,
} from "../../actions/formActions";
// import { NetlifyForm, Honeypot } from "react-netlify-forms";
const Quote = ({
  handleReset,
  handleResetSelection,
  main,
  setQuote,
  form,
  handleCategorySelect,
}) => {
  useEffect(() => {
    if (main.quoteOpen.state) {
      window.document.body.style.overflowY = "hidden";
      window.document.body.style.touchAction = "none";
    } else {
      window.document.body.style.overflowY = "scroll";
      window.document.body.style.touchAction = "auto";
    }
  }, [main.quoteOpen.state]);
  const steps = [
    "Select master blaster campaign settings",
    "Create an ad group",
    "Create an ad",
  ];
  const cat = [
    { target: { title: "Digital Branding" } },
    { target: { title: "Digital Marketing" } },
    { target: { title: "Media Production" } },
  ];
  useEffect(() => {
    // console.log("fasdfasd");
    if (main.quoteOpen.value > 0) {
      handleCategorySelect(cat[main.quoteOpen.value - 1]);
    }
  }, [main.quoteOpen.value]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleStep = (step) => () => {
  //   setActiveStep(step);
  // };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleResetx = () => {
    handleReset();
    setActiveStep(0);
    setCompleted({});
  };
  const element = document.getElementById("quote");
  const handleClickScroll = () => {
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollTo(0, 0);
      console.log("a7a");
    }
  };
  const handleFinish = (e) => {
    handleComplete(e);
    // handleSubmit();
  };
  useEffect(() => {
    handleClickScroll();
  }, []);
  useEffect(() => {
    handleClickScroll();
    if (activeStep === 0) {
      handleResetSelection();
    }
  }, [activeStep, completed]);
  return (
    <Fragment>
      <div
        onClick={() => {
          setQuote(0);
          handleReset();
        }}
        className={`dialog-background ${
          main.quoteOpen.state && "meeting-open-background"
        }`}
      ></div>
      <div
        id="quote"
        className={`dialog ${main.quoteOpen.state && "meeting-open"}`}
      >
        <span
          className="sc-close"
          onClick={() => {
            setQuote(0);
            handleReset();
          }}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </span>

        <h2 className="sc-h1">Request a quote</h2>
        <br />
        <br />
        <div className="toto">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} style={{ margin: "2rem" }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <div className="section-desc-head">
                    Your request has been sent. We&apos;ll contact you soon.
                  </div>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                      margin: "1rem",
                    }}
                  >
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleResetx}>
                      Request Another Quote
                    </Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep === 0 ? (
                    <React.Fragment>
                      <div className="section-desc-head">
                        Select your desired categories
                      </div>
                      <Page1 />
                    </React.Fragment>
                  ) : activeStep === 1 ? (
                    <React.Fragment>
                      <div className="section-desc-head">
                        Select any individual service and/or bundle
                      </div>
                      <Page2 />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="section-desc-head">
                        Finally, tell us how we can contact you
                      </div>
                      <Page3 />
                    </React.Fragment>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                      marginLeft: 2,
                      marginRight: 2,
                      marginBottom: 2,
                    }}
                  >
                    {activeStep !== 0 && (
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={() => {
                          handleClickScroll();
                          handleBack();
                        }}
                        sx={{ mr: 1 }}
                        style={{ backgroundColor: " rgb(56, 62, 70)" }}
                      >
                        Back
                      </Button>
                    )}
                    {activeStep !== 2 && (
                      <Button
                        style={{ backgroundColor: " rgb(56, 62, 70)" }}
                        onClick={handleComplete}
                        disabled={
                          (form.quote.data.services.length === 0 &&
                            form.quote.data.bundles.length === 0 &&
                            activeStep === 1) ||
                          (form.quote.selectedCategories.length === 0 &&
                            activeStep === 0)
                        }
                      >
                        Next
                      </Button>
                    )}
                    {activeStep === 2 && (
                      <Button
                        form="quote-form"
                        style={{ backgroundColor: " rgb(56, 62, 70)" }}
                        variant="outlined"
                        type="submit"
                        disabled={
                          (form.quote.data.name === "" ||
                            form.quote.data.company === "" ||
                            form.quote.data.email === "" ||
                            form.quote.data.number === "" ||
                            form.quote.data.budget === "") &&
                          activeStep === 2
                        }
                      >
                        Request Quote
                      </Button>
                    )}
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  main: state.rootReducer.main,
  form: state.rootReducer.form,
});

export default connect(mapStateToProps, {
  handleReset,
  setQuote,
  handleResetSelection,
  handleCategorySelect,
})(Quote);
