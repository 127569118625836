import React, { Fragment, useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";
import { motion } from "framer-motion";
const NavbarProfile = () => {
  function logit(e) {
    if (e.target.documentElement.scrollTop > 100) {
      return document.getElementById("nav").classList.add("shrink");
    } else {
      return document.getElementById("nav").classList.remove("shrink");
    }
  }
  const animations = {
    initial: { opacity: 0, x: -10 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -10 },
  };
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  return (
    <nav id="nav" className="nav-styles" style={{ backgroundColor: "black" }}>
      {/* logo */}
      <h2 id="logo">
        <img src={logo} alt="iano" className="logo" />
      </h2>
      {/* navigation */}
      <Link to={"/"} state={{ preload: true }} className="profile-home">
        <motion.div
          variants={animations}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.4, delay: 0.2 }}
          className="profile-link-container"
        >
          <ArrowBackIosIcon fontSize="small" />
          <span>Go back to Home</span>
        </motion.div>
      </Link>
    </nav>
  );
};

export default NavbarProfile;
