import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { display } from "@mui/system";
import React, { useEffect, useState } from "react";
// useParams
import { connect } from "react-redux";
import logo from "../../images/logo.svg";
import { setLite, setPlayVid } from "../../actions/mainActions";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
const Preloader = ({ main, setLite, setPlayVid }) => {
  let location = useLocation();
  let redirect = new URLSearchParams(location.search).get("redirect");
  const [preloaderOn, setPreloaderOn] = useState(true);
  const [removeLogo, setRemoveLogo] = useState(false);
  const [removeLogoX, setRemoveLogoX] = useState(false);
  useMemo(() => {
    if (redirect === "true") {
      setLite();
      console.log(redirect);
    }
  }, []);
  useEffect(() => {
    if (main.lite) {
      setPreloaderOn(false);
    }
  }, [main.lite]);
  useEffect(() => {
    if (main.videoLoaded && !removeLogoX) {
      setRemoveLogoX(true);
      setPreloaderOn(false);
      setPlayVid();
    }
  }, [main.videoLoaded]);
  useEffect(() => {
    setTimeout(() => {
      setRemoveLogoX(true);
    }, 2000);
    setTimeout(() => {
      setRemoveLogo(true);
    }, 2500);
  }, []);
  useEffect(() => {
    if (preloaderOn) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
    }
  }, [preloaderOn]);
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [finishedTyping, setFinishedTyping] = useState(false);
  const [cursorVisible, setCursorVisible] = useState(true);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const text1 = "Tips to load a screen faster";
  const text2 = "Tip 1: Wait for screen to load";

  useEffect(() => {
    setTimeout(() => {
      let i = 0;
      const typingInterval = setInterval(() => {
        setLine1(text1.substring(0, i));
        i++;
        if (i > text1.length) {
          clearInterval(typingInterval);
          setLine1(text1);
          setFinishedTyping(true);
        }
      }, 100 * 0.7);
    }, 2500);
  }, []);

  useEffect(() => {
    if (line2 === text2) {
      setTimeout(() => {
        setButtonVisible(true);
      }, 2000);
    }
  }, [line2]);
  useEffect(() => {
    if (finishedTyping) {
      setTimeout(() => {
        setDeleting(true);
        let i = text1.length;
        const deletingInterval = setInterval(() => {
          setLine1(text1.substring(0, i));
          i--;
          if (i < 0) {
            clearInterval(deletingInterval);
            setDeleting(false);
            setCursorVisible(false);
            let j = 0;
            const typingInterval2 = setInterval(() => {
              setLine2(text2.substring(0, j));
              j++;
              if (j > text2.length) {
                clearInterval(typingInterval2);
                setLine2(text2);
              }
            }, 100 * 0.7);
          }
        }, 100 / 3);
      }, 1000);
    }
  }, [finishedTyping]);
  useEffect(() => {
    if (main.videoLoaded && line2 === text2) {
      setTimeout(() => {
        setPlayVid();
        setPreloaderOn(false);
      }, 1000);
    }
  }, [main.videoLoaded, line2]);

  return !main.pre ? (
    <span />
  ) : (
    <div id="preloader" className={`${!preloaderOn && "remove"}`}>
      {removeLogo ? (
        <div
          className={`tips preloader typing-cursor ${
            !preloaderOn && "remove-logo"
          }`}
          style={{ whiteSpace: "pre-wrap" }}
        >
          <div className={`line1 ${line1 === "" && "none"}`}>{line1}</div>
          {/* {cursorVisible && <span className="cursor"></span>} */}
          {/* <br /> */}
          <div className="line2">{line2}</div>
          <div className={`line1 ${line1 !== "" && "none"}`}></div>
          {/* {!cursorVisible && <span className="cursor"></span>} */}
        </div>
      ) : (
        <div id="preloader" className={`preloader`}>
          <img
            src={logo}
            alt="iano"
            className={`pre-logo  ${removeLogoX && "remove-logo"}`}
          />
        </div>
      )}
      <div
        className={`hidethen ${removeLogo && "appear"} ${
          !preloaderOn && "remove-logo"
        }`}
      >
        <br />
        <br />
        <br />
        <br />
        <div className={`loader ${!preloaderOn && "remove-logo"}`}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <button
        onClick={() => setLite()}
        className={`prebutton ${buttonVisible && preloaderOn && "showbtn"} ${
          !preloaderOn && "remove-logo"
        }`}
      >
        Show me the page already!
      </button>
    </div>
  );
};

// return (
// );

const mapStateToProps = (state) => ({
  main: state.rootReducer.main,
});

export default connect(mapStateToProps, { setLite, setPlayVid })(Preloader);
//${main.videoLoaded && "remove"}
//${main.videoLoaded && "remove-logo"}
