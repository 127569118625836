import React, { useEffect, useState } from "react";
import useIntersect from "../../util/useIntersect";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import WebIcon from "@mui/icons-material/Web";
import MovieIcon from "@mui/icons-material/Movie";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import {
  setServiceAnimation,
  setServiceOpen,
} from "../../../actions/mainActions";
import ActionButoon from "../../layout/ActionButoon";
import Quote from "../../Dialogs/Quote";
const Service = ({ details, setServiceOpen, setServiceAnimation, main }) => {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-10% 0% -10% 0%",
  });
  let id = details.id;
  const [scroll, setScroll] = useState(false);

  let isScrolling;
  const setScrolling = () => {
    setScroll(true);
    window.clearTimeout(isScrolling);

    isScrolling = setTimeout(function () {
      if (scroll) {
        setScroll(false);
      }
    }, 66);
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", setScrolling, false);
    }
    watchScroll();
  });

  useEffect(() => {
    if (entry.isIntersecting && main.services.length > 0) {
      if (!main.services[details.id - 1].status && !scroll) {
        setServiceOpen(details.id);
        setServiceAnimation(details.id);
      }
    } else return;
    //eslint-disable-next-line
  }, [entry.isIntersecting, main.services, scroll]);
  const [open, setOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    if (main.services.length > 0) {
      if (main.services[details.id - 1].status) {
        return setOpen(true);
      }
    }
    //eslint-disable-next-line
  }, [main.services]);
  useEffect(() => {
    if (main.services.length > 0) {
      if (main.services[details.id - 1].animation) {
        return setAnimate(true);
      }
    }
    //eslint-disable-next-line
  }, [main.services]);
  return (
    <motion.div
      layout
      transition={{ layout: { duration: 0.6, type: "ease-in" } }}
      className="service"
      style={{ borderRadius: "20px", padding: "1.3rem" }}
      ref={ref}
    >
      <motion.div className={`s-name  ${open && "move-s-t-left"}`}>
        <motion.h1 layout="position">{details.name}</motion.h1>
        <motion.span layout="position">
          {id === 1 ? (
            <ColorLensIcon />
          ) : id === 2 ? (
            <RocketLaunchIcon />
          ) : id === 3 ? (
            <MovieIcon />
          ) : (
            <WebIcon />
          )}
        </motion.span>
      </motion.div>
      {open && (
        <motion.div className={`p-s  ${animate && "animate-p-s"}`}>
          {details.content}
          <ActionButoon s={details.id} />
        </motion.div>
      )}
    </motion.div>
  );
};
const mapStateToProps = (state) => ({
  main: state.rootReducer.main,
});

export default connect(mapStateToProps, {
  setServiceOpen,
  setServiceAnimation,
})(Service);
