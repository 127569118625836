import "../../../css/neon.scss";
import React, { useEffect, useState, memo } from "react";

import useIntersect from "../../util/useIntersect";
import { Link } from "react-scroll";
import {
  setPosition,
  setVideoLoaded,
  setVideoNotLoaded,
} from "../../../actions/mainActions";
import { connect } from "react-redux";
import Video from "./Video";
import PageTransition from "../../layout/PageTransition";
// import Preloader from "../../layout/Preloader";
const Home = (props) => {
  const { setVideoNotLoaded, setVideoLoaded, main, setPosition, loader } =
    props;
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });

  // const [linkLoading, setLinkLoading] = useState(false);
  // useEffect(() => {
  //   if (loader) setLinkLoading(false);
  // }, []);

  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-active").forEach((i) => {
          i.classList.remove("nav-active");
        });
        document.getElementById("nav-home").classList.add("nav-active");
        setPosition("home");
      }
    }
    //eslint-disable-next-line
  }, [entry.isIntersecting]);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (loaded) {
      setVideoLoaded();
    }
    //eslint-disable-next-line
  }, [loaded]);
  useEffect(() => {
    if (!main.videoLoaded) {
      window.document.body.style.overflowY = "hidden";
      window.document.body.style.touchAction = "none";
    } else {
      window.document.body.style.overflowY = "scroll";
      window.document.body.style.touchAction = "auto";
    }
    //eslint-disable-next-line
  }, [main.videoLoaded]);

  return (
    <div ref={ref}>
      <PageTransition />
      <section className="hero">
        <div>
          <Video f={setLoaded} />
          <div className="hero-content">
            <h1 className="headline-1">
              Working towards
              <br />
              Elevating your
              <br />
              Brand.
            </h1>
            <div className="wrap">
              <Link
                to="projects"
                smooth="easeInQuad"
                offset={0}
                duration={500}
                isDynamic={true}
                className="button"
              >
                view our capabilities
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="hero-para" id="clear">
        <p className="p-hero p-hero1">
          A creative marketing partner you can count on.
        </p>
        <p className="p-hero p-hero2">
          <b>"We'll bring your brand to life"</b>
        </p>
      </div>
      <div className="carzy-headline">
        <h1>Turn the tables with</h1>
        <h1 data-heading="M" className="neon">
          Memorable marketing.
        </h1>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  main: state.rootReducer.main,
});

export default connect(mapStateToProps, {
  setPosition,
  setVideoLoaded,
  setVideoNotLoaded,
})(memo(Home));
