import React from "react";
import { motion } from "framer-motion";
import AboutTextSection from "./AboutTextSection";
const data = [
  {
    title: "Established in 2016",
    text: "Inspired by change, driven by creativity, and rooted in excellence, our team inspires passion through a diverse and modern way of thinking. We have an eye for unique design which inspires the creation of compelling branding experiences that help unleash your brand identity. We have over 7 years of experience practicing in the modern marketing world.",
  },
  {
    title: "Aiming for a brighter future",
    text: "It has been done backwads for too long. We are here to change that. Consisting of start-ups, medium and large-scale businesses, client partnerships are held highly at iano. It is through effective collaboration that we are able to establish your voice and polish up your brand, even Elon Musk's satellite would spot its shiny surface. Our processes are detail-oriented yet unconventional and are designed to cater to your needs. More importantly, we love what we do, so sit back and let us work our magic!",
  },
];
const AboutText = () => {
  return (
    <div className="about-container">
      {data.map((q, i) => {
        return (
          <AboutTextSection title={q.title} text={q.text} key={i} tag={i} />
        );
      })}
    </div>
  );
};

export default AboutText;
