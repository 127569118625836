import React from "react";
import { AnimatePresence } from "framer-motion";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import NavbarProfile from "./components/layout/NavbarProfile";

const AnimatedNav = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route path="/profile/:id" element={<NavbarProfile />} />
        <Route path="/" element={<Navbar />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedNav;
