import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PageTransition from "./components/layout/PageTransition";
import PageTransition2 from "./components/layout/PageTransition2";

import MainApp from "./components/app/App";
import Profile from "./components/app/Profiles/Profile";
import { AnimatePresence } from "framer-motion";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route path="/profile/:tag" element={<Profile />} />
        <Route path="/" element={<MainApp />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
