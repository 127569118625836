import React, { useEffect } from "react";
import useIntersect from "../../util/useIntersect";
import Service from "./Service";
import { getServices, setPosition } from "../../../actions/mainActions";
import { connect } from "react-redux";
import Quote from "../../Dialogs/Quote";
const details = [
  {
    id: 1,
    name: "Digital Branding",
    content:
      "Oh, I know that brand! \n\n • Identity Design \n • Product Design \n • UI/UX Web Design \n • UX Writing \n • Content Creation",
  },
  {
    id: 2,
    name: "Digital Marketing",
    content:
      "Want to occupy the penthouse of your industry? Let's turn your leads into sales. \n\n • Search Engine Optimization (SEO) \n • Social Media Marketing \n • Pay Per Click (PPC) Management \n • Content Writing \n • Email Marketing",
  },
  {
    id: 3,
    name: "Media Production",
    content:
      "The fastet proven way to turn clicks into leads. \n\n • Display Ads \n • Video Ads \n • Radio Ads \n • Animation \n • 3D Rendering",
  },
];
const servicesStatus = details.map((s) => {
  return { id: s.id, status: false, animation: false };
});
const Services = ({ getServices, setPosition, main }) => {
  useEffect(() => {
    getServices(servicesStatus);
    // eslint-disable-next-line
  }, []);
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.querySelectorAll(".nav-active").forEach((i) => {
          i.classList.remove("nav-active");
        });
        document.getElementById("nav-services").classList.add("nav-active");
        setPosition("services");
        document.getElementById("service-title").classList.add("show-line");
      }
    }
    // eslint-disable-next-line
  }, [entry.isIntersecting]);
  return (
    <div id="services" ref={ref}>
      <h1 className="section-title" id="service-title">
        Wait there's more
      </h1>
      <div className="services-container">
        {details.map((s, i) => {
          return <Service details={s} key={i} />;
        })}
      </div>
      <Quote />
    </div>
  );
};
const mapStateToProps = (state) => ({
  main: state.rootReducer.main,
});
export default connect(mapStateToProps, {
  setPosition,
  getServices,
})(Services);
