import React from "react";
import { motion } from "framer-motion";

const PageTransition2 = () => {
  const animations = {
    initial: { x: "0%" },
    animate: { x: "100%" },
    exit: { x: "0%" },
  };
  return (
    <motion.div
      className="page-trans"
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.5, delay: 0.2 }}
    ></motion.div>
  );
};

export default PageTransition2;
