import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useIntersect from "../../util/useIntersect";
import { setQuote } from "../../../actions/mainActions";
import { connect } from "react-redux";

const FAQ = ({ setQuote }) => {
  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    setExpanded("panel1");
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-150px",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      if (entry.isIntersecting) {
        document.getElementById("contact-faq-title").classList.add("show-line");
      }
    }
  }, [entry.isIntersecting]);

  return (
    <div className="faq" ref={ref}>
      <h1 className="section-title left-end" id="contact-faq-title">
        Frequently asked questions
      </h1>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{
          background: "rgb(47, 50, 53)",
          width: "100%",
          // padding: "0.4rem",
          paddingLeft: "0.08rem",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: " rgb(46, 123, 255)" }} />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            sx={{
              flexShrink: 0,
              color: " rgb(46, 123, 255)",
              fontSize: "0.72rem",
              fontWeight: "600",
            }}
          >
            Where are you located?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            style={{
              color: "white",
              fontFamily: " 'Poppins', sans-serif",
              fontSize: "0.64rem",
            }}
          >
            Nairobi,Kenya.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        style={{
          background: "rgb(47, 50, 53)",
          width: "100%",
          // padding: "0.4rem",
          paddingLeft: "0.08rem",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: " rgb(46, 123, 255)" }} />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            sx={{
              flexShrink: 0,
              color: " rgb(46, 123, 255)",
              fontSize: "0.72rem",
              fontWeight: "600",
            }}
          >
            Do you have a physical
            <br className="show-mob" /> location?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            style={{
              color: "white",
              fontFamily: " 'Poppins', sans-serif",
              fontSize: "0.64rem",
            }}
          >
            We work remotely.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        style={{
          background: "rgb(47, 50, 53)",
          width: "100%",
          // padding: "0.4rem",
          paddingLeft: "0.08rem",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: " rgb(46, 123, 255)" }} />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            sx={{
              flexShrink: 0,
              color: " rgb(46, 123, 255)",
              fontSize: "0.72rem",
              fontWeight: "600",
            }}
          >
            Do you have a price list?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            style={{
              color: "white",
              fontFamily: " 'Poppins', sans-serif",
              fontSize: "0.64rem",
            }}
          >
            Each project is unique in its process.{" "}
            <div className="link" onClick={() => setQuote(0)}>
              Request a quote
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default connect(null, {
  setQuote,
})(FAQ);
