import {
  GET_SERVICES,
  SET_SERVICE_ANIMATION,
  SET_SERVICE_OPEN,
  SET_VIDEO_LOADED,
  SET_POSITION,
  SET_MEETING,
  SET_VIDEO_NOT_LOADED,
  SET_QUOTE,
  SET_LITE,
  SET_CANCEL_PRE,
  SET_PLAY_VID,
} from "../actions/types";

const initialState = {
  videoLoaded: false,
  services: [],
  position: "",
  meetingOpen: false,
  quoteOpen: { state: false, value: null },
  lite: false,
  pre: true,
  playVid: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO_LOADED:
      return {
        ...state,
        videoLoaded: true,
      };
    case SET_VIDEO_NOT_LOADED:
      return {
        ...state,
        videoLoaded: false,
      };
    case GET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case SET_SERVICE_OPEN:
      return {
        ...state,
        services: state.services.map((i) => {
          if (i.id === action.payload) {
            return { ...i, status: true };
          }
          return i;
        }),
      };
    case SET_SERVICE_ANIMATION:
      return {
        ...state,
        services: state.services.map((i) => {
          if (i.id === action.payload) {
            return { ...i, animation: true };
          }
          return i;
        }),
      };
    case SET_POSITION:
      return {
        ...state,
        position: action.payload,
      };
    case SET_MEETING: {
      return {
        ...state,
        meetingOpen: !state.meetingOpen,
      };
    }

    case SET_QUOTE: {
      return {
        ...state,
        quoteOpen: { state: !state.quoteOpen.state, value: action.payload },
      };
    }
    case SET_LITE: {
      return {
        ...state,
        lite: true,
      };
    }
    case SET_CANCEL_PRE: {
      return {
        ...state,
        pre: false,
      };
    }
    case SET_PLAY_VID: {
      return {
        ...state,
        playVid: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
