import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Typography } from "@mui/material";
import { setCancelPre, setLite } from "../../../actions/mainActions";
import { connect } from "react-redux";
const TeamMember = ({ details, setLite, setCancelPre }) => {
  return (
    <Link
      to={`/profile/${details.tag}`}
      className="team-paper"
      onClick={() => {
        setLite();
        setCancelPre();
      }}
    >
      <div className="team-head">
        {!details.image ? (
          <Avatar
            sx={{ fontSize: "1rem", backgroundColor: "rgb(46, 123, 255)" }}
          >
            {details.shortAvatar}
          </Avatar>
        ) : (
          <Avatar src={details.image} />
        )}
        <div className="team-head-text">
          <span>{details.name}</span>
          <Typography
            variant="body2"
            style={{ color: "lightgray", fontSize: "0.6rem" }}
          >
            {details.position}
          </Typography>
        </div>
      </div>
      {/* <p className="team-desc">{details.message}</p> */}
    </Link>
  );
};

const mapStateToProps = (state) => ({
  main: state.rootReducer.main,
});

export default connect(mapStateToProps, { setLite, setCancelPre })(TeamMember);
