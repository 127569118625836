import React from "react";

const Project = ({ data }) => {
  return (
    <div className="project-wrap">
      <div className={`project-image project-image-${data.image}`} />
      <div className="project-details">
        <span className="project-title">{data.title}</span>
        <span className="project-desc">{data.shortDesc}</span>
        <div className="project-status-wrap">
          <span className="project-status">{data.status}</span>
          <span className="project-price">{data.price}</span>
        </div>
      </div>
    </div>
  );
};

export default Project;
