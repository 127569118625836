import "./App.css";
import { Provider } from "react-redux";
import store from "./store";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import MainApp from "./components/app/App";
import Profile from "./components/app/Profiles/Profile";
import NavbarProfile from "./components/layout/NavbarProfile";
import Video from "./components/app/Home/Video";
import PageTransition from "./components/layout/PageTransition";
import PageTransition2 from "./components/layout/PageTransition2";
// import { AnimatePresence } from "framer-motion";
import AnimatedRoutes from "./AnimatedRoutes";
import AnimatedNav from "./AnimatedNav";
function App() {
  return (
    <Provider store={store}>
      <Router>
        {/* <Routes>
          <Route path="*" element={<Video />} />
        </Routes> */}

        <AnimatedRoutes />

        <AnimatedNav />
        {/* <Routes>
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/" element={<MainApp />} />
        </Routes> */}
      </Router>
    </Provider>
  );
}

export default App;
