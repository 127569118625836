import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import PageTransition from "../layout/PageTransition";
import Preloader from "../layout/Preloader";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Home from "./Home/Home";
import Projects from "./Projects/Projects";
import Services from "./Services/Services";

const App = (props) => {
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (state?.preload === true) {
      setLoader(true);
    }
  }, []);

  console.log(state, props);
  return (
    <div>
      <Preloader />
      {/* <div className="hide-lag-right" /> */}

      <Home loader={loader} />
      <Projects />
      <Services />
      <About />
      <Contact />
    </div>
  );
};

export default App;
