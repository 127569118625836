import * as React from "react";
import PropTypes from "prop-types";
// import SwipeableViews from "react-swipeable-views";
// import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Fragment } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function ProfileAbout({ about, portofolio, certificates }) {
  //   const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: "black" }}>
      <AppBar position="static">
        <Tabs
          style={{ backgroundColor: "rgb(47, 50, 53)" }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {portofolio.state ? (
            <Tab
              style={{ fontSize: "0.8rem" }}
              label="Portofolio"
              {...a11yProps(0)}
            />
          ) : certificates.state ? (
            <Tab
              style={{ fontSize: "0.8rem" }}
              label="Certificates"
              {...a11yProps(0)}
            />
          ) : !portofolio.state && !certificates.state ? (
            <Tab
              style={{ fontSize: "0.8rem" }}
              label="About Me"
              {...a11yProps(0)}
            />
          ) : (
            <div style={{ display: "none" }} />
          )}
          {portofolio.state && certificates.state ? (
            <Tab
              style={{ fontSize: "0.8rem" }}
              label="Certificates"
              {...a11yProps(1)}
            />
          ) : !portofolio.state && certificates.state ? (
            <Tab
              style={{ fontSize: "0.8rem" }}
              label="About Me"
              {...a11yProps(1)}
            />
          ) : portofolio.state && !certificates.state ? (
            <Tab
              style={{ fontSize: "0.8rem" }}
              label="About Me"
              {...a11yProps(1)}
            />
          ) : (
            <div style={{ display: "none" }} />
          )}
          {portofolio.state && certificates.state && (
            <Tab
              style={{ fontSize: "0.8rem" }}
              label="About Me"
              {...a11yProps(2)}
            />
          )}
          {/* <Tab
            style={{ fontSize: "0.8rem" }}
            label="Certificates"
            {...a11yProps(2)}
          /> */}
        </Tabs>
      </AppBar>
      {/* <SwipeableViews
        // axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      > */}
      {portofolio.state ? (
        <TabPanel value={value} index={0}>
          {
            <div className="portofolio-images-wrap">
              {portofolio.value.map((i, s) => {
                return (
                  <div
                    className="portofolio-image"
                    style={{ backgroundImage: `url(${i})` }}
                    key={s}
                  />
                );
              })}
            </div>
          }
        </TabPanel>
      ) : certificates.state ? (
        <TabPanel value={value} index={0}>
          <div className="certificate-links-wrap">
            {certificates.value.map((i, s) => {
              return <div className="certificate-link" key={s}></div>;
            })}
          </div>
        </TabPanel>
      ) : (
        <TabPanel value={value} index={0}>
          <div
            style={{
              color: "white",
              fontFamily: "'Lexend', sans-seref",
              fontSize: "0.65rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {about === 1 && (
              <Fragment>
                <Fragment>
                  <p className="bio-text">
                    Meet Mohamed Modasser, the Creative Director at iano. With
                    7+ years in Brand Development and Marketing, he's your go-to
                    visionary for creating compelling brand identities.
                  </p>
                  <br />
                  <p className="bio-text">
                    Modasser's journey took a unique turn from engineering to
                    design. Proficient in Adobe Illustrator, Adobe Photoshop,
                    and Figma, he's led 50+ successful projects and a team of
                    talented creatives. Currently, he's also pursuing a
                    Bachelor's in Mechanical Engineering.
                  </p>
                  <br />
                  <p className="bio-text">
                    Modasser thrives in the competitive marketing landscape,
                    where just 100 brands out of 7000 truly shine. His mission?
                    To be the "eye doctor" of modern marketing, strengthening
                    brand identities and foundations to ensure they stand the
                    test of time.
                  </p>
                </Fragment>
              </Fragment>
            )}
          </div>
        </TabPanel>
      )}
      {portofolio.state && certificates.state ? (
        <TabPanel value={value} index={1}>
          <div className="certificate-links-wrap">
            {certificates.value.map((i, s) => {
              return <div className="certificate-link" key={s}></div>;
            })}
          </div>
        </TabPanel>
      ) : (
        <TabPanel value={value} index={1}>
          <div
            style={{
              color: "white",
              fontFamily: "'Lexend', sans-seref",
              fontSize: "0.65rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {about === 1 && (
              <Fragment>
                <Fragment>
                  <p className="bio-text">
                    Meet Mohamed Modasser, the Creative Director at iano. With
                    7+ years in Brand Development and Marketing, he's your go-to
                    visionary for creating compelling brand identities.
                  </p>
                  <br />
                  <p className="bio-text">
                    Modasser's journey took a unique turn from engineering to
                    design. Proficient in Adobe Illustrator, Adobe Photoshop,
                    and Figma, he's led 50+ successful projects and a team of
                    talented creatives. Currently, he's also pursuing a
                    Bachelor's in Mechanical Engineering.
                  </p>
                  <br />
                  <p className="bio-text">
                    Modasser thrives in the competitive marketing landscape,
                    where just 100 brands out of 7000 truly shine. His mission?
                    To be the "eye doctor" of modern marketing, strengthening
                    brand identities and foundations to ensure they stand the
                    test of time.
                  </p>
                </Fragment>
              </Fragment>
            )}
          </div>
        </TabPanel>
      )}
      {portofolio.state && certificates.state && (
        <TabPanel value={value} index={2}>
          <div
            style={{
              color: "white",
              fontFamily: "'Lexend', sans-seref",
              fontSize: "0.65rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {about === 1 && (
              <Fragment>
                <Fragment>
                  <p className="bio-text">
                    Meet Mohamed Modasser, the Creative Director at iano. With
                    7+ years in Brand Development and Marketing, he's your go-to
                    visionary for creating compelling brand identities.
                  </p>
                  <br />
                  <p className="bio-text">
                    Modasser's journey took a unique turn from engineering to
                    design. Proficient in Adobe Illustrator, Adobe Photoshop,
                    and Figma, he's led 50+ successful projects and a team of
                    talented creatives. Currently, he's also pursuing a
                    Bachelor's in Mechanical Engineering.
                  </p>
                  <br />
                  <p className="bio-text">
                    Modasser thrives in the competitive marketing landscape,
                    where just 100 brands out of 7000 truly shine. His mission?
                    To be the "eye doctor" of modern marketing, strengthening
                    brand identities and foundations to ensure they stand the
                    test of time.
                  </p>
                </Fragment>
              </Fragment>
            )}
          </div>
        </TabPanel>
      )}
      {/* </SwipeableViews> */}
    </Box>
  );
}
